import React from "react";
import { NewAccordion } from "../templates/NewAccordion";
import { Section } from "../templates/Section";

const Technology = ({ children }) => (
  <div
    style={{ fontSize: "14px" }}
    className="text-xs border border-blue-400 text-blue-400 px-2"
  >
    {children}
  </div>
);

const LINK_STYLE =
  "font-medium text-blue-300 flex flex-row gap-2 hover:underline";

const ProjectLinks = ({ project }) => (
  <div className="flex gap-2">
    {project.Url && (
      <a
        target="_blank"
        rel="noreferrer"
        href={project.Url}
        className={LINK_STYLE}
      >
        GitHub
      </a>
    )}
    {project.DemoUrl && (
      <a
        target="_blank"
        rel="noreferrer"
        href={project.DemoUrl}
        className={LINK_STYLE}
      >
        {project.DemoText || "Demo"}
      </a>
    )}
  </div>
);

const Projects = ({ projectsData }) => {
  const renderProjectsSection = () => {
    const renderTechnologies = (technologies) => {
      return (
        <div className="flex flex-col md:flex-row gap-1">
          {technologies.map((technology, index) => {
            return <Technology key={index}>{technology}</Technology>;
          })}
        </div>
      );
    };

    return projectsData.map((project, index) => (
      <NewAccordion key={index} title={project.Title} accordionId={"projects"}>
        <div className="flex flex-col gap-2 text-xs">
          <div className="flex flex-row gap-2">
            {renderTechnologies(project.Technologies)}
          </div>
          <div>{project.Description}</div>
          <ProjectLinks project={project} />
        </div>
      </NewAccordion>
    ));
  };

  return <Section title="Projects">{renderProjectsSection()}</Section>;
};

export default Projects;
