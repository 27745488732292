import React from "react";
import { ReactComponent as GithubLogo } from "../assets/Octicons-mark-github.svg";
import { ReactComponent as LinkedinLogo } from "../assets/linkedin.svg";
import { ReactComponent as EmailLogo } from "../assets/email.svg";

const ICON_STYLE = "w-4 h-4 fill-blue-600";

const LINKS = {
  github: <GithubLogo className={ICON_STYLE} />,
  linkedin: <LinkedinLogo className={ICON_STYLE} />,
  email: <EmailLogo className={ICON_STYLE} />,
};

const Top = ({ data }) => {
  const header = () => (
    <h1 className="text-4xl font-extrabold text-blue-300">
      Hi, I'm Renjie
      <span className="wave" role="img" aria-labelledby="wave">
        👋🏻
      </span>
    </h1>
  );

  const body = () => (
    <div className="flex flex-col gap-2">
      <h2 className="text-2xl font-normal">computer science graduate</h2>
      <h2 className="text-2xl font-normal">and software developer.</h2>
      {links()}
    </div>
  );

  const links = () => (
    <div className="flex flex-row gap-4">
      {data.Links.map((link, index) => (
        <a
          key={index}
          href={link.Url}
          target="_blank"
          rel="noreferrer"
          title={link.Title}
          className="rounded-full bg-white w-8 h-8 flex items-center justify-center"
        >
          {LINKS[link.Icon]}
        </a>
      ))}
    </div>
  );

  return (
    <div className="flex flex-col min-h-screen space-y-[10vh] justify-center">
      {header()}
      {body()}
    </div>
  );
};

export default Top;
