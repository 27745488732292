import React from "react";

export const NewAccordion = ({ title, accordionId, children }) => {
  return (
    <div className="collapse collapse-arrow rounded-md shadow-sm drop-shadow-md bg-slate-800 mb-2">
      <input type="radio" name={accordionId} />
      <div className="collapse-title font-medium text-blue-300">{title}</div>
      <div className="collapse-content">{children}</div>
    </div>
  );
};
