import React from "react";

export const ExperienceComp = ({ heading, subheading, date }) => {
  return (
    <div>
      <h3 className="font-medium text-blue-200">{heading}</h3>
      <h3 className="text-xs">{subheading}</h3>
      <h3 className="text-xs">{date}</h3>
    </div>
  );
};
