import "./App.css";
import { useEffect } from "react";
import Top from "./components/Top";
import Education from "./components/Education";
import Projects from "./components/Projects";
// import Skills from "./components/Skills";
import Experience from "./components/Experience";
import data from "./resources/resume.json";
import ReactGA from "react-ga";
import "particles.js";
import particleConfig from "./resources/particleconfig.json";

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    window.particlesJS("particles-js", particleConfig);
  }, []);

  return (
    <div className="App h-full object-center flex flex-col items-center">
      <Top data={data} />
      <Education educationData={data.Education} />
      <Experience experienceData={data.Experience} />
      {/* <Skills skillsData={data.Skills} /> */}
      <Projects projectsData={data.Projects} />
      <div className="h-6"></div>
      <div id="particles-js"></div>
    </div>
  );
}

export default App;
