import React from "react";

export const BulletList = ({ items }) => {
  return (
    <div>
      <ul className="list-disc pl-4">
        {items.map((text, index) => {
          return (
            <li key={index} className="text-sm mt-2">
              {text}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
