import React from "react";
import { SectionHeader } from "./SectionHeader";

export const Section = ({ title, children }) => {
  return (
    <div
      style={{ background: "#121316" }}
      className="flex flex-col mb-12 max-w-screen-sm mx-8 rounded-md p-6 drop-shadow-lg border-gray-600"
    >
      {title && <SectionHeader>{title}</SectionHeader>}
      <div className=" h-0 p-0 m-0 my-4 leading-none w-1/2 border border-blue-600"></div>
      <div>{children}</div>
    </div>
  );
};
