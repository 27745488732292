import React from "react";
import { ExperienceComp } from "../templates/ExperienceComp.jsx";
import { BulletList } from "../templates/List.jsx";
import { Section } from "../templates/Section.jsx";

const Experience = ({ experienceData }) => {
  return (
    <Section title="Experience">
      {experienceData.map((experience, index) => {
        return (
          <div key={index}>
            <ExperienceComp
              heading={`${experience.Company} - ${experience.Role}`}
              subheading={experience.Location}
              date={experience.Dates}
            />
            <BulletList items={experience.Details} />
          </div>
        );
      })}
    </Section>
  );
};

export default Experience;
