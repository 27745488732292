import React from "react";
import { ExperienceComp } from "../templates/ExperienceComp.jsx";
import { BulletList } from "../templates/List.jsx";
import { Section } from "../templates/Section.jsx";

const Education = ({ educationData }) => {
  return (
    <Section title="Education">
      <ExperienceComp
        heading={educationData.School}
        subheading={educationData.Degree}
        date={educationData.Dates}
      />
      <BulletList items={educationData.Details} />
    </Section>
  );
};

export default Education;
